import React from "react";
import { ColorRing } from "react-loader-spinner";
import uploadImage from "../../assets/images/fileUpload.png";

const FileInput = ({uploading,OnFileChange}) => {
  return (
    <div className="flex justify-center items-center w-full"
      
    style={{
      position:"relative"
    }}
    >
      <label
        for="dropzone-file"
        className="w-full h-full cursor-pointer"
        // className="flex flex-col justify-center items-center w-full h-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"

        onChange={(e)=>{
           
            console.log(e.target.value)
          OnFileChange(e.target.files[0])
       }}
       diable={uploading}
      >
        <div>
          



{

uploading && (
  <dvi className="file-upload-spinner">


  <ColorRing
                 visible={true}
                 height="50"
               width="50"
     ariaLabel="blocks-loading"
     wrapperStyle={{}}
     wrapperClass="blocks-wrapper"
     colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
       className="file-upload-spinner"
   />
     </dvi>
)

}

 
     <img src={uploadImage} alt="Upload" className="w-full h-full" />


          

       
        </div>
        <input id="dropzone-file" type="file" className="hidden"
        
        diable={uploading}
        />
      </label>
    </div>
  );
};

export default FileInput;
